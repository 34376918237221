import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Grid, Cell } from '@trbl/react-css-grid';
import ShapedImage from '../ShapedImage';
import { LogoNew } from '../../common/LogoNew'
import LinkList from '../../common/Nav/Menu/MenuList/LinkList';
import './Footer.scss';
import DownloadPDF from '../DownloadPDF/DownloadPDF';
import SocialMedia from '../../common/SocialMedia/SocialMedia';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query footerData {
      businessInfo: file(relativePath: { eq: "business-info.md"}) {
        childMarkdownRemark {
          frontmatter {
            address
            city
            state
            zip
            phoneNumber
            displayNumber
          }
        }
      }
      backgroundImage: file(relativePath: {eq: "factory.jpg"}) {
        childImageSharp {
          fluid(grayscale: true, maxWidth: 1440, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      backgroundImageMobile: file(relativePath: {eq: "factory.jpg"}) {
        childImageSharp {
          fluid(grayscale: true, maxWidth: 640, maxHeight: 800, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  const {
    address,
    city,
    state,
    zip,
    phoneNumber,
    displayNumber,
  } = data.businessInfo.childMarkdownRemark.frontmatter;
  const backgroundSources = [
    data.backgroundImageMobile.childImageSharp.fluid,
    {
      ...data.backgroundImage.childImageSharp.fluid,
      media: `(min-width: 640px)`,
    },
  ];
  return (
    <footer className="wmfab__footer">
      <ShapedImage
        topCorner="right"
        color="black"
        // backgroundImage={data.backgroundImage}
        backgroundImage={backgroundSources}
      />
      <Grid
        className="wmfab__footer--content"
      >
        <Cell
          hStart={3}
          hSpan={8}
          hStartS={2}
          hSpanS={14}
          className="wmfab__footer--info"
        >
          <div className="wmfab__footer--logo-address">
            <LogoNew />
              <address>
                {address}<br/>
                {city}, {state} {zip}<br/>
                <a href={`tel:+1${phoneNumber}`}>
                  {displayNumber}
                </a>
              </address>
            <SocialMedia />
          </div>
          <DownloadPDF />
          <small>
            At West Michigan Fab Corporation, our highest priority is satisfied customers. You are important to us and you can expect us to go the extra mile for your business.
          </small>
          <small className="wmfab__footer--copyright">
            &copy; 2020 West Michigan Fab Corp<br/>
            Powered by <a href="https://www.grandrapids.tech">grandrapids.tech</a>
          </small>
        </Cell>
        <Cell
          hSpan={6}
          hStartS={2}
          hSpanS={14}
          className="wmfab__footer--nav"
        >
          <nav>
            <ul className="wmfab__footer--link-list">
              <LinkList full />
            </ul>
          </nav>
        </Cell>
      </Grid>
    </footer>
  );
};

export default Footer;
