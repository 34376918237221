import React from "react";
import { MenuContextProvider } from "../context/MenuContext";
import Nav from '../common/Nav';
import Footer from '../components/Footer';
import "../scss/resets.scss";
import "../scss/app.scss";

const PageLayout = ({ children }) => {
  return (
    <MenuContextProvider>
      <Nav/>
      <div>
        {children}
      </div>
      <Footer />
    </MenuContextProvider>
  );
}

export default PageLayout;
