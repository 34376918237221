import React, { useContext } from 'react';
import loadable from '@loadable/component';
import { MenuContext } from '../../../context/MenuContext';
import './Menu.scss';

const MenuList = loadable(() => import('./MenuList'));

const Menu = React.memo(() => {
  const {menuOpen} = useContext(MenuContext);
  return (
    <div
      id="app--nav-menu"
      className={`wmfab__menu${menuOpen ? ` open` : ''}`}
      aria-hidden={!menuOpen}
    >
      {menuOpen && (
        <MenuList />
      )}
    </div>
  );
});

export default Menu;
