import React, { useContext } from 'react';
import { MenuContext } from '../../../context/MenuContext';
import './ToggleIcon.scss';

const ToggleIcon = React.memo(() => {
  const { menuOpen } = useContext(MenuContext);
  return (
    <div className={`wmfab__nav--toggle-icon${menuOpen ? ' open' : ' closed'}`}>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
        <g id="toggle--close">
          <line className="wmfab__nav--toggle-icon-lines" x1="2" y1="2" x2="12" y2="12"/>
          <line className="wmfab__nav--toggle-icon-lines" x1="2" y1="12" x2="12" y2="2"/>
        </g>
        <g id="toggle--open">
          <g id="toggle--bottom">
            <rect className="wmfab__nav--toggle-icon-blocks" y="10" width="4" height="4" rx="1"/>
            <rect className="wmfab__nav--toggle-icon-blocks" x="10" y="10" width="4" height="4" rx="1"/>
            <rect className="wmfab__nav--toggle-icon-blocks" x="5" y="10" width="4" height="4" rx="1"/>
          </g>
          <g id="toggle--middle">
            <rect className="wmfab__nav--toggle-icon-blocks" y="5" width="4" height="4" rx="1"/>
            <rect className="wmfab__nav--toggle-icon-blocks" x="5" y="5" width="4" height="4" rx="1"/>
            <rect className="wmfab__nav--toggle-icon-blocks" x="10" y="5" width="4" height="4" rx="1"/>
          </g>
          <g id="toggle--top">
            <rect className="wmfab__nav--toggle-icon-blocks" x="5" width="4" height="4" rx="1"/>
            <rect className="wmfab__nav--toggle-icon-blocks" x="10" width="4" height="4" rx="1"/>
            <rect className="wmfab__nav--toggle-icon-blocks" width="4" height="4" rx="1"/>
          </g>
        </g>
      </svg>
    </div>
  );
});

export default ToggleIcon;
