import React from 'react'
import PropTypes from 'prop-types'
import {
    FaFacebook,
    FaLinkedin
} from 'react-icons/fa'
import useWindowSize from '../../hooks/useWindowSize';
import './SocialMedia.scss';


const SocialMedia = ({ iconSize }) => {
    const { width } = useWindowSize();
    const adjustedIconSize = width < 1212 ? '1.728rem' : iconSize;

    return (
        <ul className="wmfab__social-media" style={{ height: adjustedIconSize }}>
            <li>
                <a
                    href="https://www.facebook.com/westmichiganfab/"
                    aria-label="Link to West Michigan Fab Corp's Facebook Page"
                >
                    <FaFacebook size={adjustedIconSize} />
                </a>
            </li>
            <li>
                <a
                    href="https://in.linkedin.com/company/wmfab"
                    aria-label="Link to West Michigan Fab Corp's LinkedIn Page"
                >
                    <FaLinkedin size={adjustedIconSize} />                    
                </a>
            </li>
        </ul>
    );
}

SocialMedia.defaultProps = {
    iconSize: '2rem',
};

SocialMedia.propTypes = {
    iconSize: PropTypes.string,
};

export default SocialMedia