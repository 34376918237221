/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = React.memo(({
  lang = "en-US",
  title,
  description,
  ogImage,
  meta,
  article,
  pathname,
  noIndex,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description || site.siteMetadata.description,
        },
        {
          name: 'robots',
          content: noIndex ? 'noindex, follow' : 'index, follow'
        },
        {
          property: 'og:locale',
          content: lang,
        },
        {
          property: `og:title`,
          content: title || site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: description || site.siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `${article ? 'article' : 'website'}`,
        },
        {
          property: 'og:url',
          content: `https://www.wmfabcorp.com${pathname}`,
        },
        {
          property: 'og:site_name',
          content: site.siteMetadata.title,
        },
        {
          property: 'og:image',
          content: ogImage ? `http://www.wmfabcorp.com${ogImage.src}` : null,
        },
        {
          property: 'og:image:secure_url',
          content: ogImage ? `https://www.wmfabcorp.com${ogImage.src}` : null,
        },
        {
          property: 'og:image:width',
          content: ogImage ? ogImage.width : null,
        },
        {
          property: 'og:image:height',
          content: ogImage ? ogImage.height : null,
        },
        {
          name: 'twitter:image',
          content: ogImage ? `https://www.wmfabcorp.com${ogImage.src}` : null,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title || site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: description || site.siteMetadata.description,
        },
      ].concat(meta)}
    />
  )
});

SEO.defaultProps = {
  lang: `en-US`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO;
