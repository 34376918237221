import React, { useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import { MenuContext } from '../../context/MenuContext';
import Menu from './Menu';
import { LogoNew } from '../LogoNew';
import ToggleIcon from './ToggleIcon';
import './Nav.scss';

const Nav = () => {
  const {menuOpen, toggleMenu } = useContext(MenuContext);

  useEffect(() => {
    // Locks the html element of the document when the nav is open to prevent page scroll
    if (typeof document !== 'undefined') {
      const html = document.querySelector('html');
      menuOpen ? (html.style.overflow = 'hidden') : (html.style.overflow = 'visible');
    }
  }, [menuOpen])

  return (
    <>
      <nav
        id="nav"
        role="navigation"
      >
        <Menu/>
        <button
          onClick={() => toggleMenu()}
          className={`
            wmfab__nav--toggle
            ${menuOpen ? ' wmfab__nav--toggle-open' :  '' }
          `}
          aria-expanded={menuOpen}
          aria-controls="app--nav-menu"
          tabIndex="0"
          style={{
            position: 'absolute',
          }}
        >
          <span>            
            <ToggleIcon/>
            {menuOpen ? ' Close' : ' Menu'}
          </span>
        </button>
        <Link to="/">
          <LogoNew />
        </Link>
      </nav>
    </>
  )
}

export default Nav
