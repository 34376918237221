import React from 'react';
import { Link } from 'gatsby';

const LinkList = ({ full }) => {
  const links = [
    {
      name: 'Home',
      url: '/',
    },
    {
      name: 'About',
      url: '/about/',
    },
    {
      name: 'Contact',
      url: '/contact/',
    },
    {
      name: 'Legal',
      url: '/terms-of-service/',
    },
  ];
  const additionalLinks = [
    {
      name: 'Services',
      url: '/services/',
    },
    {
      name: 'Machines',
      url: '/machines/',
    },
  ];

  return (
    <>
      {links.map(({ name, url }) => (
        <li key={name}>
          <Link to={url} >{name}</Link>
        </li>
      ))}
      {full && (
        additionalLinks.map(({ name, url }) => (
          <li key={name}>
            <Link to={url}>{name}</Link>
          </li>
        ))
      )}
    </>
  );
}

export default LinkList;
