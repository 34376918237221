import React from 'react'
import ISOCert from '../../assets/images/west-mi-fabrication-iso-9001-certificate-2022.pdf'

function DownloadPDF() {
    return (
        <small>
            <a href={ISOCert}>Download our ISO 9001 Certification</a>{``}
        </small>
    )
}

export default DownloadPDF