import React from 'react';
import Image from 'gatsby-image';
import './ShapedImage.scss';

const ShapedBlock = React.memo(({
  topCorner = 'left',
  bottomCorner = null,
  color = 'yellow',
  backgroundImage = null,
}) => {
  return (
    <div className={`
        wmfab__shaped-image
        ${topCorner ? `top-${topCorner}` : ''}
        ${bottomCorner ? `bottom-${bottomCorner}` : ''}
      `}
    >
      <div className="wmfab__shaped-image--container">
        {backgroundImage && (
          <Image
            fluid={Array.isArray(backgroundImage) ? backgroundImage : backgroundImage.childImageSharp.fluid}
            style={{ height: "100%"}}
          />
        )}
        <div
          className={`
            wmfab__shaped-image--overlay
            wmfab__overlay--${color}
          `}
        />
      </div>
    </div>
  );
});

export default ShapedBlock
